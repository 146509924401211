import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency } from 'atc-js';

import {
    ListColumns,
    SeeMore,
    Text,
} from 'reaxl';
import { FAQPage as FAQPageSchema } from 'reaxl-schema';

import {
    getPricingModelList,
    getPricingTrimList,
} from '@/utilities/getPricingSEOData';
import getSrpPageTypes from '@/utilities/getSrpPageType';

import { kbbVRSDataDuck, queryDuck } from '@/ducks';

import {
    srpCrawlPathsDataMapsDuck,
    srpSEOPricingDuck,
} from '@/ducks/srp';

function FaqSEOContentContainer({ modelYearList, trimList }) {
    const dispatch = useDispatch();

    const queryList = useSelector(queryDuck.selectors.getDuckState);
    const pageType = getSrpPageTypes(queryList);
    const { isYMMUrl, isYMMTUrl } = pageType;
    const { makeCode, modelCode, startYear } = queryList;

    const loadSafetyInfo = useCallback(() => dispatch(srpSEOPricingDuck.creators.loadSafetyInfo()), [dispatch]);

    useEffect(() => {
        if (!!makeCode && !!modelCode) {
            loadSafetyInfo();
        }
    }, [loadSafetyInfo, makeCode, modelCode, startYear]);

    const { makesMap, modelsMap } = useSelector(srpCrawlPathsDataMapsDuck.selectors.getDuckState);
    const makeKnown = queryList?.makeCode && !Array.isArray(queryList?.makeCode);
    const modelKnown = queryList?.modelCode && !Array.isArray(queryList?.modelCode);
    const trimKnown = queryList?.trimCode && !Array.isArray(queryList?.trimCode);
    const trimName = trimKnown ? queryList?.trimCode?.split('|')?.[1] : '';

    const makeName = makeKnown && makesMap ? makesMap[queryList?.makeCode.toUpperCase()] : '';
    const modelName = modelKnown && modelsMap ? modelsMap[queryList?.modelCode] : '';

    const activeTrims = useSelector(kbbVRSDataDuck.selectors.getActiveModelTrims);
    const selectedTrim = useSelector(kbbVRSDataDuck.selectors.getSelectedMakeModelTrims);

    const seoSafetyInfo = useSelector(srpSEOPricingDuck.selectors.getSafetyInfo) || [];

    const pricingModelList = getPricingModelList(modelYearList, makeName, modelName, trimName);
    const pricingTrimList = getPricingTrimList(trimList, makeName, modelName);
    const exactTrimPricing = pricingTrimList?.find((entry) => entry.trimCode === trimName);

    const mmLabel = `${makeName} ${modelName}`;
    const ymmLabel = `${startYear} ${mmLabel}`;
    const ymmtLabel = `${ymmLabel} ${trimName}`;

    const faqItems = [];
    let typeLabel;

    if (isYMMUrl) {
        typeLabel = ymmLabel;

        if (pricingModelList) {
            const ymmPriceString = `${typeLabel}: ${pricingModelList?.links?.[0]?.formattedMinPrice} - ${pricingModelList?.links?.[0]?.formattedMaxPrice}`;

            faqItems.push({
                question: `How much should I pay for a used ${typeLabel}?`,
                answer: ymmPriceString,
                formattedAnswer: (
                    <>
                        <Text
                            componentClass="div"
                        >
                            Autotrader price range:
                        </Text>

                        <Text
                            className="padding-top-3"
                            componentClass="div"
                        >
                            {ymmPriceString}
                        </Text>
                    </>
                ),
            });
        }

        if (activeTrims) {
            const mpgString = (trim) => `${trim?.trimName} ${trim?.bodyStyle}: ${trim?.epaCity} mpg in the city and ${trim?.epaHwy} mpg on the highway`;
            const evString = (trim) => `${trim?.trimName} ${trim?.bodyStyle}: ${trim?.epaMpgeCity} MPGe in the city and ${trim?.epaMpgeCity} MPGe on the highway`;
            const onlyOneYearOfData = activeTrims.filter((trim) => trim.year === activeTrims[0]?.year);
            const onlyAvailableMpgs = onlyOneYearOfData.filter((trim) => (!!trim?.epaCity && !!trim.epaHwy) || (!!trim?.epaMpgeCity && !!trim?.epaMpgeHwy));
            const mpgsList = onlyAvailableMpgs.map((trim) => (trim?.engineType !== 'Electric' ? mpgString(trim) : evString(trim)));

            faqItems.push({
                question: `What is the MPG of the ${ymmLabel}?`,
                answer: mpgsList.toString(),
                formattedAnswer: (
                    <>
                        This number varies by trim:
                        <ListColumns
                            className="list-bulleted padding-top-3 padding-left-0 text-size-300"
                            items={mpgsList}
                        />
                    </>),
            });

        }
    }

    if (isYMMTUrl) {
        typeLabel = ymmtLabel;

        if (exactTrimPricing) {
            const formattedStartingPrice = formatCurrency(exactTrimPricing?.startingPrice);
            const formattedMaxPrice = formatCurrency(exactTrimPricing?.maxPrice);
            const ymmtPriceString = `${formattedStartingPrice} - ${formattedMaxPrice}`;

            faqItems.push({
                question: `How much should I pay for a used ${typeLabel}?`,
                answer: ymmtPriceString,
                formattedAnswer: (
                    <>
                        <Text
                            componentClass="div"
                        >
                            Autotrader price range:
                        </Text>
                        <Text
                            className="padding-top-3"
                            componentClass="div"
                        >
                            {ymmtPriceString}
                        </Text>
                    </>
                ),
            });
        }

        if (selectedTrim.length > 0) {
            const mpgString = `${selectedTrim[0]?.epaCity} mpg in the city and ${selectedTrim[0]?.epaHwy} mpg on the highway`;
            const evString = `${selectedTrim[0]?.epaMpgeCity} mpge in the city and ${selectedTrim[0]?.epaMpgeHwy} mpge on the highway`;
            const mpgAnswer = selectedTrim[0]?.engineType !== 'Electric' ? mpgString : evString;

            faqItems.push({
                question: `What is the MPG of the ${typeLabel}?`,
                answer: mpgAnswer,
                formattedAnswer: mpgAnswer,
            });
        }

        if (seoSafetyInfo.length > 0) {
            const safetyInfoSpecs = seoSafetyInfo[0]?.features.Specifications;
            const legRoom = !!safetyInfoSpecs && safetyInfoSpecs.filter(({ label }) => label.includes('Legroom'));

            const legroomItems = !!legRoom && legRoom.map(({ label, value }) => {
                const location = label.split(' ');
                const addRow = location.includes('First' || 'Second' || 'Third');
                return addRow ? `${location[0]} row: ${value} of legroom` : `${location[0]}: ${value} of legroom`;
            });

            const renderLegroomItems = legroomItems.map((item) => (
                <span key={`${item.location}-item.value}`}>
                    {item}
                </span>
            ));

            faqItems.push({
                question: `What is the legroom of ${typeLabel}?`,
                answer: legroomItems.toString(),
                formattedAnswer: !!legRoom
                && (
                    <ListColumns
                        className="list-bulleted padding-left-0 text-size-300"
                        items={renderLegroomItems}
                    />
                ),
            });
        }
    }

    const renderQuestions = () => !!faqItems && faqItems.map(({ index, question, formattedAnswer }) => (
        <div
            className="margin-bottom-5"
            key={index}
        >
            {!!question && (
                <Text
                    weight="bold"
                    componentClass="h3"
                    size={400}
                >
                    {question}
                </Text>
            )}

            {!!formattedAnswer && (
                <Text className="padding-top-3">
                    {formattedAnswer}
                </Text>
            )}
        </div>
    ));

    const faqSchemaItems = !!faqItems && faqItems.map(({ formattedAnswer, ...item }) => item);

    return (
        <div className="padding-top-4">
            <SeeMore>
                {renderQuestions()}
            </SeeMore>
            {!!faqSchemaItems.length && <FAQPageSchema content={faqSchemaItems} />}
        </div>
    );
}

export default FaqSEOContentContainer;

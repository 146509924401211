import React from 'react';

import { InventoryListingPlaceholder } from 'reaxl-listing';

function SimilarInventoryContainerPlaceholder() {
    // Placeholder for 20 listings plus the Transition Card
    return Array(21).fill().map((_, index) => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-simListings"
            key={`inventory-placeholder-simListings-${index}`}
        />
    ));
}

export default SimilarInventoryContainerPlaceholder;

import React, { memo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';
import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';

import { Carousel, Chip } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { clearChipClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import { compareFilterChipsList } from '@/utilities/filterChipUtil';

import { srpFiltersDuck, srpSelectedFiltersInOrderDuck } from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function ChipsWrapperComponent({ children, isWrapped, container, containerProps }) {
    if (isWrapped) {
        return React.createElement(container, containerProps, children);
    }
    return children;
}

export function InventoryFiltersChipsContainer({
    showFallbackMessage = true,
    showFilterChips = true,
    noWrap = false,
    carouselProps = {},
}) {
    const dispatch = useDispatch();

    const {
        filter_chips_srp: [enableFilterChips],
    } = useFeatures([
        'filter_chips_srp',
    ]);

    const navigateToSrp = useSrpNavigation();

    const activeFilterValues = useSelector(srpFiltersDuck.selectors.getSelectedFilterChipsInOrder, _isEqual);

    useEffect(() => {
        // Comparing logic in React.memo prevents the component from re-rendering,
        // therefore this update does not trigger a new re-render of the component
        dispatch(srpSelectedFiltersInOrderDuck.creators.setSelectedFiltersInOrder({ filterChipsInOrder: activeFilterValues }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilterValues]);

    const deleteAction = useCallback((filter) => {
        let payload = '';

        switch (filter.name) {
            case 'maxPrice':
            case 'minPrice': {
                payload = ['minPrice', 'maxPrice'];
                dispatch(srpFiltersDuck.creators.clearFilters(payload));
                break;
            }
            case 'startYear':
            case 'endYear': {
                payload = ['startYear', 'endYear'];
                dispatch(srpFiltersDuck.creators.clearFilters(payload));
                break;
            }
            default:
                payload = {
                    filter,
                    value: filter.value,
                    isMultiple: filter.isMultiple,
                };
                dispatch(srpFiltersDuck.creators.deselectFilter(payload));
        }
    }, [dispatch]);

    const handleClearChip = useCallback(async (event, filter) => {
        const { value } = event.target;
        await interactionResponse();
        event.target.value = value;
        sendClick(clearChipClick, event, {
            removedValue: filter.label,
        });

        // update redux state
        deleteAction({
            name: filter.filterName,
            value: filter.value,
            isMultiple: true,
        });

        navigateToSrp({ resetPagination: true, isNewSearch: true });
    }, [deleteAction, navigateToSrp]);

    const noSelectionMessage = !showFallbackMessage ? '' : (
        <span className="text-subdued-lightest"> Select filters to narrow your results. </span>
    );

    return (enableFilterChips && showFilterChips && activeFilterValues?.length > 0) ? (
        <span
            data-cmp="filterChips"
            className={clsx('margin-top-2', { 'display-flex flex-nowrap': noWrap })}
        >
            <ChipsWrapperComponent
                isWrapped={Object.keys(carouselProps).length > 0}
                container={Carousel}
                containerProps={{ ...carouselProps, className: 'margin-top-2' }}
            >
                {activeFilterValues.map((filter) => (
                    <Chip
                        className="margin-bottom-2"
                        glyph="remove"
                        uiContext="input"
                        key={`filter-chip-${filter.label}-${filter.filterName}`}
                        onClick={(event) => handleClearChip(event, filter)}
                        {...filter}
                    />
                ))}
            </ChipsWrapperComponent>
        </span>
    ) : noSelectionMessage;
}

export default memo(InventoryFiltersChipsContainer, (oldProps, newProps) => {
    const { activeFilterValues: oldActiveFilterValues, ...restOldProps } = oldProps;
    const { activeFilterValues: newActiveFilterValues, ...restNewProps } = newProps;

    return _isEqual(restOldProps, restNewProps) && compareFilterChipsList(oldActiveFilterValues, newActiveFilterValues);
});

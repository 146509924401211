import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import xss from 'xss';

import {
    Tab,
    Tabs,
    Text,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';

import getSrpPageTypes from '@/utilities/getSrpPageType';
import useCompareTrimsData from '@/utilities/useCompareTrimsData';

import { queryDuck } from '@/ducks';

import consumerRatingsDuck from '@/ducks/consumerRatingsDuck';
import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
    srpSEOPricingDuck,
    srpTrimCrawlPathsDuck,
    srpYearCrawlPathsDuck,
} from '@/ducks/srp';
import srpContentDuck from '@/ducks/srp/srpContentDuck';

import ConsumerRatingsContainer from '@/containers/ConsumerRatingsContainer';
import PricingSEOContentContainer from '@/containers/PricingSEOContentContainer';

import CompareTrimsContainer from './CompareTrimsContainer';
import FaqSEOContentContainer from './FaqSEOContentContainer';
import SafetyReferenceContainer from './SafetyReferenceContainer';

const ALLOWED_MICRODATA_ATTRIBUTES = ['itemscope', 'itemtype', 'itemprop'];
const ALLOWED_TAGS = {
    a: ['target', 'href', 'title', ...ALLOWED_MICRODATA_ATTRIBUTES],
    div: [...ALLOWED_MICRODATA_ATTRIBUTES],
    span: [...ALLOWED_MICRODATA_ATTRIBUTES],
    p: [...ALLOWED_MICRODATA_ATTRIBUTES],
    strong: [...ALLOWED_MICRODATA_ATTRIBUTES],
    br: [...ALLOWED_MICRODATA_ATTRIBUTES],
    li: [...ALLOWED_MICRODATA_ATTRIBUTES],
    ul: ['class', ...ALLOWED_MICRODATA_ATTRIBUTES],
    ol: ['class', ...ALLOWED_MICRODATA_ATTRIBUTES],
    sup: [...ALLOWED_MICRODATA_ATTRIBUTES],
    h2: [...ALLOWED_MICRODATA_ATTRIBUTES],
    h3: [...ALLOWED_MICRODATA_ATTRIBUTES],
    h4: [...ALLOWED_MICRODATA_ATTRIBUTES],
};
const COMP_TRIM_HEADER = 'Compare Trims';
const CONSUMER_REVIEWS_HEADER = 'Consumer Reviews';
const SAFETY_HEADER = 'Safety';
const PRICE_TAB_HEADER = 'Pricing';
const FAQ_HEADER = 'FAQs';

const getSelectedOptions = (filter, filtersOptions) => {
    const options = _get(filtersOptions, `${filter}.options`, []);

    return options.filter((option) => option.selected);
};

const getSelectedFilters = (filtersOptions, filtersValues) => {
    const selectedMakes = getSelectedOptions('makeCode', filtersOptions);
    let selectedModels = [];
    let selectedTrims = [];
    const selectedYear = {};

    if (selectedMakes.length === 1) {
        selectedModels = getSelectedOptions(`modelCode.modelCode|${[selectedMakes[0].value]}`, filtersOptions);

        if (selectedModels.length === 1) {
            // If trims = 0 or 1 return true
            selectedTrims = getSelectedOptions(`trimCode.trimCode|${selectedModels[0].value}`, filtersOptions);

            const { startYear, endYear } = filtersValues;
            if (startYear && endYear) {
                if (startYear.value === endYear.value) {
                    selectedYear.label = startYear.value;
                }
            }
        }
    }

    return { selectedMakes, selectedModels, selectedTrims, selectedYear };
};

const getTrimName = (query) => {
    const trimKnown = query?.trimCode && !Array.isArray(query?.trimCode);
    return trimKnown ? query?.trimCode.split('|')?.[1] ?? query?.trimCode : '';
};
const getFormattedSectionTitle = (selectedModels, selectedYear, selectedMakes, query, makeSummaryData) => {
    if (selectedModels[0] && selectedModels[0].label) {
        const yearLabel = selectedYear.label ? selectedYear.label + ' ' : '';
        return `${yearLabel}${selectedMakes[0].label} ${selectedModels[0].label} ${getTrimName(query)}`;
    }
    // If make and no model use makeContent reference title
    if (selectedMakes[0] && selectedMakes[0].label && makeSummaryData?.summary?.heading) {
        return `About ${makeSummaryData?.summary?.heading}`;
    }

    return null;
};

const renderTabContent = (sectionData) => (
    <div
        className="margin-vertical-4"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: xss(sectionData, { allowList: ALLOWED_TAGS }) }}
    />
);

const renderContent = (content) => content?.map((sectionData, index) => {
    if (sectionData) {
        const { header = '', body, component } = sectionData;

        const trimmedEventKey = header.split(' ').join('');

        return (
            <Tab
                key={_camelCase(header)}
                eventKey={trimmedEventKey}
                title={(
                    <Text
                        componentClass="h3"
                        weight="bold"
                        size={400}
                    >
                        {header}
                    </Text>
                )}
                className="margin-horizontal-2"
                style={{ position: 'relative' }}
            >
                {component}
                {renderTabContent(body)}
                {index !== 0 && renderTabContent(body)}
            </Tab>
        );
    }
    return (
        <Tab
            key={null}
            eventKey={null}
            title={null}
        />
    );
});

function ModelReferenceContainer() {
    const {
        consumer_ratings: [enableConsumerRatings, { srpRatings: enableSrpRatings }],
    } = useFeatures([
        'consumer_ratings',
    ]);
    const dispatch = useDispatch();

    const editorialContent = useSelector(srpContentDuck.selectors.getEditorials);
    const makeSummaryData = useSelector(srpContentDuck.selectors.getMakeSummaryData);
    const filtersOptions = useSelector(srpFiltersDuck.selectors.getFiltersOptions);
    const filtersValues = useSelector(srpFiltersDuck.selectors.getValuesState);
    const activeModel = useSelector(srpActiveInteractionDuck.selectors.getActiveModel);
    const query = useSelector(queryDuck.selectors.getDuckState);
    const trimName = getTrimName(query);
    const { yearsData } = useSelector(srpYearCrawlPathsDuck.selectors.getYearsData);
    const { trimData } = useSelector(srpTrimCrawlPathsDuck.selectors.getTrimsData);
    const loadPricingModelYearsData = useCallback(() => dispatch(srpSEOPricingDuck.creators.loadModelYearsData()), [dispatch]);
    const loadPricingTrimsData = useCallback(() => dispatch(srpSEOPricingDuck.creators.loadTrimsData()), [dispatch]);
    const pricingModelYearsData = useSelector(srpSEOPricingDuck.selectors.getModelYears);
    const pricingTrimData = useSelector(srpSEOPricingDuck.selectors.getTrims);
    const modelCode = _get(useSelector(queryDuck.selectors.getDuckState), 'modelCode', '');
    const makeCode = _get(useSelector(queryDuck.selectors.getDuckState), 'makeCode', '');
    const safetyInfo = useSelector(srpSEOPricingDuck.selectors.getSafetyInfo);
    const loadSafetyInfo = useCallback(() => dispatch(srpSEOPricingDuck.creators.loadSafetyInfo()), [dispatch]);
    const consumerRatings = useSelector(consumerRatingsDuck.selectors.getConsumerRatings);

    const setActiveInteraction = (options) => dispatch(srpActiveInteractionDuck.creators.setKeys(options));

    const { isMMUrl, isMMTUrl, isYMMTUrl, isYMMUrl } = getSrpPageTypes(query);
    const { selectedMakes, selectedModels, selectedYear } = getSelectedFilters(filtersOptions, filtersValues);
    const startYear = query?.startYear;
    const compareTrimsMakeModel = isMMUrl || isYMMUrl ? { makeCode, modelCode } : {};
    const showCompareTrims = useCompareTrimsData(compareTrimsMakeModel);
    const modelRules = !!modelCode && typeof modelCode !== 'object';
    const makeRules = !!makeCode && typeof makeCode !== 'object';
    const trimCode = _get(useSelector(queryDuck.selectors.getDuckState), 'trimCode', '');
    const showSafetyTab = makeRules && modelRules && !trimCode && !_isEmpty(safetyInfo);
    const displayConsumerRatings = Object.keys(consumerRatings).length !== 0 && consumerRatings?.numberOfReviews !== 0;
    const showConsumerReviewsTab = enableConsumerRatings && enableSrpRatings && displayConsumerRatings;

    const modelYearList = _isEmpty(yearsData) ? pricingModelYearsData : yearsData?.links;
    const trimList = _isEmpty(trimData) ? pricingTrimData : trimData?.links;
    const hasModelYearsData = (isMMUrl || isYMMUrl || isMMTUrl) && modelYearList?.length > 0;
    const hasTrimData = isYMMTUrl && trimList?.length > 0;
    const hasPricingData = (trimName && startYear) ? hasTrimData : hasModelYearsData;
    const showPricingTab = (query.listingType === 'USED') && hasPricingData;

    const [content, setContent] = useState(editorialContent || []);
    const [tabKeyMap, setTabKeyMap] = useState({});
    const [activeTab, setActiveTab] = useState('');

    const priceContent = {
        header: PRICE_TAB_HEADER,
        component: <PricingSEOContentContainer
            modelYearList={modelYearList}
            trimList={trimList}
        />,
    };

    const compareTrimsContent = {
        header: COMP_TRIM_HEADER,
        component: <CompareTrimsContainer />,
    };

    const safetyContent = {
        header: SAFETY_HEADER,
        component: <SafetyReferenceContainer safetyInfo={safetyInfo} />,
    };

    const consumerReviewsContent = {
        header: CONSUMER_REVIEWS_HEADER,
        component: <ConsumerRatingsContainer consumerRatings={consumerRatings} />,
    };

    const faqContent = {
        header: FAQ_HEADER,
        component: <FaqSEOContentContainer
            modelYearList={modelYearList}
            trimList={trimList}
        />,
    };
    useEffect(() => {
        if (!!makeCode && !!modelCode) {
            loadSafetyInfo();
        }
    }, [loadSafetyInfo, makeCode, modelCode, startYear]);

    useEffect(() => {
        if (_isEmpty(yearsData) || _isEmpty(trimData)) {
            if (isYMMTUrl) {
                loadPricingTrimsData();
            } else {
                loadPricingModelYearsData();
            }
        }
    }, [loadPricingTrimsData, loadPricingModelYearsData, trimData, yearsData, isYMMTUrl]);

    useEffect(() => {
        const newContent = [...editorialContent];
        const newTabKeyMap = {};

        // Tab Order: -> [Altezza Editorial Tabs], ..., Overview, FAQ, Trim Comparison

        // Show Pricing Tab for Pages(UMM/UYMM/UMMT/UYMMT)
        if (showPricingTab) { newContent.push(priceContent); }

        // FAQ Tab
        if (showPricingTab && (isYMMUrl || isYMMTUrl)) { newContent.push(faqContent); }

        // Compare Safety Tab
        if (showSafetyTab) { newContent.push(safetyContent); }

        // Compare Consumer Ratings Tab
        if (showConsumerReviewsTab) { newContent.push(consumerReviewsContent); }

        // Compare Trims Tab
        if (showCompareTrims) { newContent.push(compareTrimsContent); }

        // build tabKey map
        newContent.forEach((tab, index) => {
            newTabKeyMap[index] = tab.header;
        });

        setContent(newContent);
        setTabKeyMap(newTabKeyMap);
        setActiveTab(newTabKeyMap[0]); // if a new first (default) tab loads, set the active tab to that tab

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorialContent, showCompareTrims, showSafetyTab, showConsumerReviewsTab, showPricingTab, isMMUrl, isYMMUrl, isMMTUrl, isYMMTUrl, modelYearList, trimList]);

    const loadCompareTrimTab = () => {
        if (!activeModel || activeModel === 'none') {
            setActiveInteraction({ isTrimsTabOpen: true, activeModel: [modelCode] });
        }
    };

    const handleSelect = (key) => {
        setActiveTab(key);
        loadCompareTrimTab();
    };

    const renderTabs = () => {
        // immediately trigger load for compare trims data
        if (tabKeyMap[0] === COMP_TRIM_HEADER) { loadCompareTrimTab(); }

        return (
            <Tabs
                defaultActiveKey={tabKeyMap[0]}
                activeKey={activeTab}
                alignment="left"
                onSelect={handleSelect}
                className="row"
                style={{
                    overflowY: 'scroll',
                    height: '400px',
                }}
            >
                {renderContent(content)}
            </Tabs>
        );
    };

    return !!content?.length && (
        <div
            className="container margin-vertical-4"
            data-cmp="SRP-ModelReferenceContainer"
            id="model-reference-container"
        >
            <h2 className="text-ultra-bold margin-bottom-2">
                {getFormattedSectionTitle(selectedModels, selectedYear, selectedMakes, query, makeSummaryData)}
            </h2>

            {renderTabs()}
        </div>
    );
}
export default ModelReferenceContainer;

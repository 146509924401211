import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export default function getElectricShopper({ filtersOptions, filtersValues, peData, query }) {
    const selectedFuelTypeGroups = _get(filtersValues, 'fuelTypeGroup', []);

    // Has at least one model and fuel Type has Electric
    const hasMakes = !_isEmpty(query?.makeCodeList || query?.makeCode);
    const fuelTypeGroupOption = _get(filtersOptions, 'fuelTypeGroup.options', []);
    const getFuelList = () => fuelTypeGroupOption && fuelTypeGroupOption.find((option) => option.value === 'ELE');
    const hasElectricFuel = getFuelList()?.value === 'ELE';
    const hasElectricModel = hasMakes && hasElectricFuel;

    // PE data has EV
    const preferredFuel = _get(peData, 'PrefFuelCat', null);
    const recentPreferredFuel = _get(peData, 'RecentPrefFuelCat', null);
    const marketingSegments = _get(peData, 'pe_clusters', []);

    return selectedFuelTypeGroups.includes('ELE') || hasElectricModel || preferredFuel === 'Electric'
        || recentPreferredFuel === 'Electric' || marketingSegments.includes('EVSHOPPER');
}

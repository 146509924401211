import { useCallback } from 'react';

import _get from 'lodash/get';

import {
    inventoryCarouselPagination,
    inventoryPaymentDetailsClick,
    listingPaymentCTAClick,
    ownerViewInventory,
    snapshotPaymentDetailsClick,
} from 'reaxl-analytics-handlers';

import getAlphaOwnerWebsiteLink from '@/utilities/getAlphaOwnerWebsiteLink';
import handleClickableListing from '@/utilities/handleClickableListing';
import vdpUrlBuilder from '@/utilities/vdpUrlBuilder';

import {
    inventoryClick,
    inventoryImpressions,
    ownerLogoClick,
    ownerShowHideInventory,
} from '@/analytics/srpAnalyticsHandlers';

import { srpActiveInteractionDuck } from '@/ducks/srp';

const AlphaHandlers = {

    useAlphaWalletClick(sendClick, showcaseListing = {}, parentId = '', updateMyWallet) {
        return useCallback((event) => {
            event.stopPropagation();
            const extra = {
                inventoryId: showcaseListing.id,
                suffix: '_a',
                par: parentId,
            };
            sendClick(snapshotPaymentDetailsClick, event, extra);
            updateMyWallet({
                listingId: showcaseListing.id,
                showPaymentDetailsModal: true,
                listingCategory: parentId,
            });
        }, [parentId, sendClick, showcaseListing, updateMyWallet]);
    },

    /* handle collapsible header click for InventoryAlphaListing */
    useCollapsibleHeaderClick(sendClick, showcaseListing, parentId) {
        return useCallback((event) => {
            const extra = {
                inventoryId: showcaseListing.id,
                parentId,
            };
            sendClick(ownerShowHideInventory, event, extra);
        }, [sendClick, showcaseListing, parentId]);
    },

    /* handle get directions click for InventoryAlphaListing & OwnerAlphaListing */
    useGetDirectionsClick(sendClick, showcaseListing, owner, ownerId, parentId) {
        return useCallback((event) => {
            const mapUrl = _get(owner, 'location.map.href', false);
            const extra = {
                ownerId,
                cmp: 'get_dir_a',
                par: parentId,
                inventoryId: showcaseListing && showcaseListing.id,
                pixallData: {
                    listingType: parentId,
                },
            };

            event.stopPropagation();
            if (mapUrl) {
                sendClick('ownerLocation', event, extra);
            }
        }, [sendClick, owner, ownerId, parentId, showcaseListing]);
    },

    useAlphaListingClick(sendClick, showcaseListing, parentId, isOwnerAlpha, navigateToVdp, vdpWebsite, dispatch) {
        return useCallback(async (event) => {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                showSpinningIndicator: true,
            }));

            handleClickableListing(event, () => {
                if (!isOwnerAlpha) {
                    sendClick(inventoryClick, event, {
                        inventoryId: showcaseListing.id,
                        par: parentId,
                        paginatedListingIndex: 0,
                        pixallData: {
                            eventSource: 'vehicle',
                        },
                    });
                }
            });

        }, [sendClick, isOwnerAlpha, parentId, showcaseListing, dispatch]);
    },

    /* handle view owner inventory click for InventoryAlphaListing & OwnerAlphaListing in desktop view*/
    useOwnerInventoryClick(sendClick, showcaseListing, ownerId, enableOwnerInventoryHandler, dealerDetailUrl, parentId) {
        return useCallback(async (event) => {
            event.stopPropagation();
            const extra = {
                co_txt_url: event.target.textContent || '',
                inventoryId: showcaseListing && showcaseListing.id,
                ownerId,
                par: parentId,
            };
            if (enableOwnerInventoryHandler) {
                await sendClick(ownerViewInventory, event, extra);
                window.location.href = dealerDetailUrl;
            }
        }, [showcaseListing, ownerId, parentId, enableOwnerInventoryHandler, sendClick, dealerDetailUrl]);
    },

    /* handle view payment details click for InventoryAlphaListing */
    useViewPaymentDetailsClick(sendClick, showcaseListing, parentId) {
        return useCallback((event) => {
            const extra = {
                inventoryId: showcaseListing.id,
                par: parentId,
                cmp: 'view_pymt_dtl_a',
                clickType: 'alpha',
                paginatedListingIndex: 0,
            };
            event.stopPropagation();

            sendClick(inventoryPaymentDetailsClick, event, extra);
        }, [sendClick, showcaseListing, parentId]);
    },

    /* handle pricing details click for InventoryAlphaListing */
    usePricingDetailsClick(showcaseListing, navigateToVdp, vdpWebsite) {
        return useCallback((event) => {

            // needed to allow anchors within listing for SEO while using the correct handlers
            event.stopPropagation();
            event.preventDefault();

            navigateToVdp({
                url: vdpWebsite + '#pricingDetails',
                listingId: showcaseListing.id,
            });

        }, [navigateToVdp, vdpWebsite, showcaseListing]);
    },

    /* handle owner logo tile click for InventoryAlphaListing & OwnerAlphaListing */
    useTileClick(sendClick, showcaseListing, ownerId, parentId) {
        return useCallback(async (event, owner) => {
            const extra = {
                ownerId,
                inventoryId: showcaseListing && showcaseListing.id,
                par: parentId,
                pixallData: {
                    listingType: parentId,
                },
            };
            event.stopPropagation();
            await sendClick(ownerLogoClick, event, extra);

            window.open(getAlphaOwnerWebsiteLink(owner));

        }, [sendClick, showcaseListing, ownerId, parentId]);
    },

    useInViewCarouselChange(sendImpressions, listings) {
        return useCallback(async (index, direction, width, selectedCells) => {
            const subArray = selectedCells.length * index;
            const listingsDisplayed = listings.slice(subArray, (subArray + selectedCells.length));
            await sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'alp',
                    selectedItemIds: listingsDisplayed.map(({ id }) => id),
                    pixallData: {
                        isCarousel: true,
                    },
                    startingIndex: subArray,
                },
            });
        }, [listings, sendImpressions]);
    },

    useOnCarouselLoad(sendImpressions, listings) {
        return useCallback(async (index, selectedCells) => {
            const subArray = selectedCells.length * index;
            const listingsDisplayed = listings.slice(subArray, (subArray + selectedCells.length));
            await sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'alp',
                    selectedItemIds: listingsDisplayed.map(({ id }) => id),
                    pixallData: {
                        isCarousel: true,
                    },
                    startingIndex: subArray,
                },
            });
        }, [listings, sendImpressions]);
    },

    useCarouselArrowClickNext(sendClick, showcaseListing, ownerId) {
        return useCallback((event) => {
            const extra = {
                ownerId,
                direction: 'next',
                inventoryId: showcaseListing.id,
            };
            sendClick(inventoryCarouselPagination, event, extra);
            // avoid sending multuple analytics events
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    },

    useCarouselArrowClickPrevious(sendClick, showcaseListing, ownerId) {
        return useCallback((event) => {
            const extra = {
                ownerId,
                direction: 'previous',
                inventoryId: showcaseListing.id,
            };
            sendClick(inventoryCarouselPagination, event, extra);
            // avoid sending multuple analytics events
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    },

    useInventoryListingClick(sendClick, showcaseListing, parentId, navigateToVdp, carouselListings, dispatch) {
        return useCallback(async (event, listing, index, clickType = 'alpha') => {
            if (typeof dispatch === 'function') {
                dispatch(srpActiveInteractionDuck.creators.setKeys({
                    showSpinningIndicator: true,
                }));
            }

            const extra = {
                par: parentId,
                inventoryId: listing.id,
                pixallData: {
                    eventSource: 'vehicle',
                },
            };

            const vdpUrl = vdpUrlBuilder({ baseUrl: carouselListings?.[index]?.vdpBaseUrl, clickType });

            // needed to allow anchors within listing for SEO while using the correct handlers
            event.stopPropagation();
            event.preventDefault();

            await sendClick(inventoryClick, event, extra);

            navigateToVdp({
                url: vdpUrl,
                listingId: listing.id,
            });
        }, [sendClick, navigateToVdp, parentId, carouselListings, dispatch]);
    },

    usePaymentCTAClick(sendClick, showcaseListing, parentId, updateMyWallet) {
        return useCallback((event) => {
            const extra = {
                par: parentId,
                inventoryId: showcaseListing.id,
                clickType: 'alpha',
                paginatedListingIndex: 0,
            };

            event.stopPropagation();
            sendClick(listingPaymentCTAClick, event, extra);

            updateMyWallet({
                displayModal: true,
            });
        }, [sendClick, showcaseListing, parentId, updateMyWallet]);
    },
};

export default AlphaHandlers;

import deepmerge from 'deepmerge';
import indexArray from 'index-array';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import { formatCurrency } from 'atc-js';

import {
    buildVDPBaseUrl,
    getCpoSuggestedFilters,
    getFourthSpotlightTier,
    listingDataPreprocessing,
} from '@/utilities/dispatchUtils';
import { createSummaryContent } from '@/utilities/summaryContentUtilities';

import {
    inventoryDuck,
    ownersDuck,
    requestParamsDuck,
    userDuck,
} from '@/ducks';

import {
    srpAdsDuck,
    srpContentDuck,
    srpEditorialReviewsDuck,
    srpExpiredListingMessageDuck,
    srpFiltersDuck,
    srpMarketExtensionDuck,
    srpNewCarBoostDuck,
    srpNewSearchDuck,
    srpNoSearchResultsDuck,
    srpPaginationDuck,
    srpPrimeSpotlightDuck,
    srpResultsDuck,
    srpReuseActiveFourthSpotlightDuck,
    srpReuseActiveStateDuck,
    srpSortDuck,
    srpSponsorshipDuck,
    srpSpotlightDuck,
    srpSuggestedFiltersDuck,
    srpSuggestedMakeModelValuesDuck,
} from '@/ducks/srp';

import buildSRPFilterGroupReferenceData from './srp/buildSRPFilterGroupReferenceData';
import buildSRPFiltersReferenceData from './srp/buildSRPFiltersReferenceData';

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

export default function dispatchSearchResultsPageData() {
    return async (ctx, next) => {
        const state = ctx.store.getState();

        const reuseActiveState = srpReuseActiveStateDuck.selectors.getDuckState(state);

        const vdpUrl = await buildVDPBaseUrl(ctx);
        const {
            reference_filters: [showRefFilters],
            default_num_records: [, { sortOptions: defaultNumRecordsOptions }],
            oem_cpo_srp: [, { suggested_filter: enableCpoSuggestedFilter }],
        } = ctx.useFeatures([
            'reference_filters',
            'default_num_records',
            'oem_cpo_srp',
        ]);

        const filters = showRefFilters ? await buildSRPFiltersReferenceData(ctx, false) : ctx?.data?.filters;
        const filterGroups = showRefFilters ? await buildSRPFilterGroupReferenceData(ctx, true) : ctx?.data?.filterGroups;

        ctx.batchDispatch.createActions((deferredCtx) => {
            const requestId = deferredCtx?.data?.requestParams?.requestId;

            // if page is not done loading before a new filter is selected, abort the current page load
            if (!typeof window === 'undefined' && requestId && window.sessionStorage.getItem('requestId')
                && requestId !== window.sessionStorage.getItem('requestId')) {

                next('abort queue');
            }

            const {
                _cookies,
                alpha,
                boost,
                editorialContent,
                editorialReviews,
                listings = [],
                owners = [],
                makeSummaryData,
                pageData = {},
                spotlights = [],
                standardSpotlights = {},
                featuredSpotlights = {},
                stats,
                totalResultCount,
            } = deferredCtx.data;

            if (_cookies) {
                const searchLbCookie = _cookies.search_lbcookie;
                if (searchLbCookie && ctx.cookies) {
                    ctx.cookies.set('search_lbcookie', searchLbCookie, { path: '/' });
                }
            }
            const _actions = [];

            // Feature Flags
            const {
                brand: [, brandVariables],
                standard_listing_phone_display: [displayPhoneNumber],
                standard_listing_owner_name: [hideOwnerName],
                premium_spotlight: [isPremiumSpotlightEnabled],
            } = ctx.useFeatures([
                'brand',
                'standard_listing_phone_display',
                'standard_listing_owner_name',
                'premium_spotlight',
            ]);

            if (!reuseActiveState) {

                const alphaListings = _get(alpha, 'alphaShowcase', []);
                const spotlightListings = _get(spotlights, 'inventory', false) || _get(spotlights, 'listings', []);
                const spotlightOwners = spotlights?.owners || [];
                const premiumSpotlightListings = isPremiumSpotlightEnabled ? spotlightListings.splice(3) : [];
                const {
                    listings: [standardSpotlightListing] = [],
                    owners: [standardSpotlightOwner] = [],
                } = standardSpotlights;
                const {
                    listings: [featuredSpotlightListing] = [],
                    owners: [featuredSpotlightOwner] = [],
                } = featuredSpotlights;
                const boostListings = _get(boost, 'listings', []);

                // Get fourth spotlight listing if we have featured and/or standard listings in the listings results set
                const fourthSpotlightTier = getFourthSpotlightTier(listings);
                let fourthSpotlightListing;
                if (fourthSpotlightTier === 'Standard' && standardSpotlightListing && standardSpotlightOwner) {
                    fourthSpotlightListing = standardSpotlightListing;
                    spotlightOwners.push(standardSpotlightOwner);
                } else if (fourthSpotlightTier === 'Featured' && featuredSpotlightListing && featuredSpotlightOwner) {
                    fourthSpotlightListing = featuredSpotlightListing;
                    spotlightOwners.push(featuredSpotlightOwner);
                }

                // aggregate all the listings from the page
                const aggregatedInventory = deepmerge.all([
                    // add the inventory from listings serverData
                    indexArray(listings, 'id'),

                    // add the spotlight listings if they exist
                    indexArray(spotlightListings, 'id'),

                    // add the premium spotlight listings if they exist
                    indexArray(premiumSpotlightListings, 'id'),

                    // add the fourth spotlight listing if it exists
                    (fourthSpotlightListing ? { [fourthSpotlightListing.id]: fourthSpotlightListing } : {}),

                    // add alpha listing
                    indexArray(alphaListings, 'id'),

                    // add new car boost listings
                    indexArray(boostListings, 'id'),
                ], { arrayMerge: overwriteMerge });

                const inventoryImageUrl = brandVariables.inventory_image_url;
                if (inventoryImageUrl && aggregatedInventory) {
                    Object.values(aggregatedInventory).forEach((listing) => {
                        // Allow 4 images for premium spotlight alert and premium spotlight peakaboo (only 1 premium spotlight will
                        // be returned for these spotlights)
                        const maxImageCount = premiumSpotlightListings.length === 1 && listing.id === premiumSpotlightListings[0].id ? 4 : 1;
                        listingDataPreprocessing({
                            displayPhoneNumber,
                            hideOwnerName,
                            inventoryImageUrl,
                            listing,
                            owners,
                            vdpUrl,
                            maxImageCount,
                        });
                    });

                    // Update the inventory collection on each page load
                    _actions.push(inventoryDuck.creators.addInventory(aggregatedInventory));
                }

                const defaultMetaDescription = brandVariables.default_meta_description;

                const summaryContentData = createSummaryContent(deferredCtx.data, deferredCtx.query);

                // aggregate all the owners
                const aggregatedOwners = deepmerge.all([
                    // add the owners from the owners serverData
                    indexArray(owners, 'id'),
                    indexArray(spotlightOwners, 'id'),
                    indexArray(_get(boost, 'owners'), 'id'),
                ]);
                // Reduce the amount of data that we dispatch/gets stored in __NEXT_DATA__
                Object.values(aggregatedOwners).forEach((owner) => {
                    if (owner.images?.sources?.length > 1) {
                        owner.images.sources.length = 1;
                    }
                });

                // Flag that there are any invalid query params
                const invalidQuery = deferredCtx.invalidQueryParams && deferredCtx.invalidQueryParams.length > 0;

                // flag for a no results found page
                const noResultsFound = totalResultCount === 0;

                pageData.summaryContentData = summaryContentData;
                pageData.makeSummaryData = makeSummaryData;
                pageData.editorialContent = editorialContent;
                pageData.defaultMetaDescription = defaultMetaDescription;

                const minPrice = stats?.derivedprice?.min;
                const maxPrice = stats?.derivedprice?.max;
                const minPricePlaceholder = minPrice && formatCurrency(minPrice);
                const maxPricePlaceholder = maxPrice && formatCurrency(maxPrice);
                // Set filters based on brand
                if (!_isEmpty(filters) && !_isEmpty(brandVariables?.filters)) {
                    Object.assign(filters, brandVariables?.filters);
                }

                const zipQuery = deferredCtx?.query?.zip;
                if (zipQuery && typeof zipQuery === 'string') {
                    const updatedZip = zipQuery.substring(0, 5);
                    if (!updatedZip.match(/^\d{5}$/)) {
                        delete deferredCtx.query.zip;
                    } else {
                        deferredCtx.query.zip = updatedZip;
                    }
                }

                // Add fourth spotlight listing to original list of spotlight listings at index 3 if it exists
                // IMPORTANT - We must explicitly add the fourth spotlight at index 3 in case there are fewer than
                // 3 spotlights in the original spotlights list
                const spotlightListingIds = spotlightListings.map((spotlight) => spotlight.id);
                if (fourthSpotlightListing?.id) {
                    spotlightListingIds[3] = fourthSpotlightListing?.id;
                }

                const cpoListingType = filters?.listingType?.options?.find(
                    (listingType) => listingType?.label === 'Manufacturer Certified'
                );

                const suggestedFilters = _get(deferredCtx, 'data.suggestedFilters', []);

                // Add cpo suggested filters
                let cpoSuggestedFilters = [];
                if (enableCpoSuggestedFilter) {
                    cpoSuggestedFilters = getCpoSuggestedFilters(cpoListingType, filters, suggestedFilters);
                }

                _actions.push(
                    // overwrite request params on each page change
                    requestParamsDuck.creators.setObject(deferredCtx.data.requestParams || {}),

                    // Process and set filters
                    // TODO: BONNET - optimizations?  Do we need to set the values if we already have them?
                    // perhaps we can query current state and check for filter values and ignore if they are already present?
                    srpFiltersDuck.creators.setSrpFilters({
                        filters,
                        filterGroups,
                        placeholders: {
                            minPrice: minPricePlaceholder,
                            maxPrice: maxPricePlaceholder,
                        },
                        query: deferredCtx.query,
                    }),

                    // Update the owners collection
                    ownersDuck.creators.addOwners(aggregatedOwners),

                    // Map the order to display results
                    srpResultsDuck.creators.setActiveResults(listings.map((listing) => listing.id)),

                    // Update the spotlight active results
                    srpSpotlightDuck.creators.setActiveResults(spotlightListingIds),

                    // Update the premium spotlight active results
                    srpPrimeSpotlightDuck.creators.setActiveResults(premiumSpotlightListings.map((spotlight) => spotlight.id)),

                    // Update the result count
                    srpResultsDuck.creators.setCount(invalidQuery ? 0 : totalResultCount),

                    // Update stats
                    srpResultsDuck.creators.setStats({
                        ...stats,
                        endYear: stats?.year?.max,
                        maxPrice,
                        minPrice,
                        startYear: stats?.year?.min,
                        searchRadius: deferredCtx.query.searchRadius,
                        zip: deferredCtx.query.zip,
                    }),

                    srpContentDuck.creators.setContent({ pageData }),

                    // TODO: BONNET - in page updates defaulted this to false.. do we need to recreate this somehow?
                    srpExpiredListingMessageDuck.creators.setKey('redirectExpiredPage', !!deferredCtx.query.redirectExpiredPage),

                    // Set market extension
                    srpMarketExtensionDuck.creators.setKeys({
                        active: _get(pageData, 'marketExtensionDma', true),
                        value: _get(deferredCtx, 'query.marketExtension', 'include'),
                        // TODO: BONNET - this was on filter change but not initial... weird
                        initializing: true,
                    }),

                    // Set the page's current page
                    srpPaginationDuck.creators.deriveCurrentPage({
                        firstRecord: deferredCtx.query.firstRecord,
                        numRecords: deferredCtx.query.numRecords || defaultNumRecordsOptions?.value,
                    }),

                    srpNoSearchResultsDuck.creators.setKeys({
                        showNoResults: !totalResultCount,
                    }),

                    srpSortDuck.creators.setSort(deferredCtx.query.sortBy || 'relevance'),
                    srpSponsorshipDuck.creators.setBoolean(deferredCtx.query.isSponsorship === 'true'),
                    srpSuggestedMakeModelValuesDuck.creators.setKeys(pageData.suggestedModels),

                    srpSuggestedFiltersDuck.creators.setKey('filters', (enableCpoSuggestedFilter && cpoSuggestedFilters.length > 0) ? cpoSuggestedFilters : suggestedFilters),
                );
                if (boostListings && boostListings.length > 0) {
                    _actions.push(srpNewCarBoostDuck.creators.setActiveResults(boostListings.map((listing) => listing.id)));
                }

                if (editorialReviews) {
                    if (Array.isArray(editorialReviews)) {
                        editorialReviews.forEach((review) => _set(review, 'guid.rendered', ''));
                    }

                    _actions.push(srpEditorialReviewsDuck.creators.set(editorialReviews));
                }

                // Update no results data
                if (noResultsFound || invalidQuery) {
                    _actions.push(srpNoSearchResultsDuck.creators.setKeys({
                        showNoResults: invalidQuery || noResultsFound,
                        message: 'Update filter criteria to see more results',
                    }));
                }
            }

            if (reuseActiveState) {
                _actions.push(
                    srpReuseActiveStateDuck.creators.setFalse(),
                    srpReuseActiveFourthSpotlightDuck.creators.setTrue(),
                );
            } else {
                _actions.push(srpReuseActiveFourthSpotlightDuck.creators.setFalse());
            }

            // Access isNewSearch directly from the Redux store
            const isNewSearch = srpNewSearchDuck.selectors.getDuckState(ctx.store.getState());
            _actions.push(

                // Set the page level targeting for Ads
                // TODO: BONNET - Should this not be getting passed to the Ads Provider?
                // Why is it in Redux?
                srpAdsDuck.creators.setPageLevelTargeting({
                    filters,
                    filterGroups,
                    query: deferredCtx.query,
                    // ...ads,
                    ...pageData.ad,
                }),

                // Flag that this is a new search, if no query param, assume it is a new search
                // TODO: BONNET - The fact that we are using query params to track makes me sad
                srpNewSearchDuck.creators.setBoolean(isNewSearch),

                // Save the current search
                userDuck.creators.setSavedSearchItem({
                    saveSearchString: _get(pageData, 'saveSearchData.myAtcSaveSearchString', ''),
                    textModalDisclaimer: _get(pageData, 'saveSearchData.textModalDisclaimer', ''),
                    title: _get(pageData, 'saveSearchData.bookmarkTitle', ''),
                    searchBookmarkDate: _get(pageData, 'saveSearchData.bookmarkSavedDateFormatted', ''),
                    saveSearchInstrText: _get(pageData, 'saveSearchData.saveSearchInstrText', ''),
                }),
            );

            return _actions;
        });

        // dispatch the batched action queue
        await ctx.batchDispatch.dispatchActions(ctx);

    };
}

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { getPath, pageNames } from '@atc/bonnet-paths';

import {
    Button,
    Glyphicon,
    Heading,
    IconButton,
    Image,
    Link,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { psxPrivateSellerExchangeLogoClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';

import remoteActions from '@/actions/srp/remoteActions';

import {
    authDuck,
    queryDuck,
    userDuck,
} from '@/ducks';

import srpContentDuck from '@/ducks/srp/srpContentDuck';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import FilterLocationModalContainer from './FilterLocationModalContainer';
import InventoryClearFilters from './InventoryClearFilters';
import KeywordSearchContainer from './KeywordSearchContainer';
import SaveSearchContainer from './SaveSearchContainer';
import SRPBreadcrumbsContainer from './SRPBreadcrumbsContainer';
import SuggestedFilterContainer from './SuggestedFilterContainer';

const renderHeading = ({ locationHeading }) => (
    <>
        <Heading
            componentClass="h1"
            size={500}
            className="display-inline margin-right-2"
            id="search-results-heading"
        >
            {locationHeading}
        </Heading>
        <FilterLocationModalContainer />
    </>
);

function KeywordButton({ onClick }) {
    return (
        <IconButton
            label="Search by keyword"
            glyph="search"
            hideLabel
            size="small"
            onClick={onClick}
            className="col-xs-1 padding-top-1"
        />
    );
}

const renderBreadCrumbsAndHeading = (locationHeading, showLink, path, linkText) => (
    <>
        <SRPBreadcrumbsContainer />
        {showLink && (
            <Link
                href={path ?? 'cars-for-sale/all-cars'}
                className="text-bold margin-top-2"
                undecorated
            >
                {linkText}
            </Link>
        )}
        <div className="display-md-flex align-items-md-center margin-top-2">
            {renderHeading({ locationHeading })}
        </div>
    </>
);

function SrpHeroContainer({
    dataCmp,
    heroAlt,
    heroSrc,
    isXs,
    linkText,
    locationHeading,
    path,
}) {
    return (
        <div
            data-cmp={dataCmp}
            className="container-fluid bg-blue-lightest padding-vertical-4"
        >
            <div className="container padding-horizontal-sm-0 padding-horizonal-2">
                <div className="display-flex justify-content-between align-items-center">
                    <div className="display-flex flex-column">
                        {renderBreadCrumbsAndHeading(locationHeading, true, path, linkText)}
                    </div>
                    <div>
                        <Image
                            src={heroSrc}
                            width={isXs ? 188 : 376}
                            height={isXs ? 50 : 100}
                            alt={heroAlt}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SearchResultsTitleContainer() {
    const dispatch = useDispatch();

    const device = useDevice();
    const lessThanMd = _get(device, 'lessThan.md', false);
    const isXs = _get(device, 'is.xs', false);

    const { locationHeading } = useSelector(srpContentDuck.selectors.getDuckState);
    const {
        searchBookmarkId = null,
        bookmarkId = null,
        sellerType = '',
        experience = '',
        city = '',
        dma = {},
        zip = '',
    } = useSelector(queryDuck.selectors.getDuckState) || {};

    const savedSearch = useSelector(userDuck.selectors.getSavedSearch) || {};
    const savedSearchTitle = savedSearch.title;
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);

    const [keywordExpand, setKeywordExpand] = useState(false);
    const navigateToSrp = useSrpNavigation();

    const [personalVehiclesPath, setPersonalVehiclesPath] = useState('');

    const {
        commercial_experience: [enableCommercialExperience, {
            name: experienceName,
        }],
        enable_private_seller_exchange_logo: [enablePrivateSellerExchangeLogo],
        hero: [, {
            src: heroSrc,
            alt: heroAlt,
        }],
        link_text: [, {
            text: linkText,
        }],
        personalization: [isPersonalizationEnabled] = [],
        psx_logo: [, {
            icon: privateSellerIcon,
            href: privateSellerExchangeLogoHref,
            width: privateSellerIconWidth,
        }],
        suggested_keyword: [enableKeyword],
    } = useFeatures([
        'commercial_experience',
        'enable_private_seller_exchange_logo',
        'hero',
        'link_text',
        'personalization',
        'psx_logo',
        'suggested_keyword',
    ]);

    useEffect(() => {
        if (searchBookmarkId && isLoggedIn) {
            dispatch(remoteActions.loadSavedSearchBookmark());
        }
        if (bookmarkId) {
            dispatch(remoteActions.handleSearchRedirect(false, navigateToSrp));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBookmarkId]);

    const inSrpExperience = (experience && enableCommercialExperience);

    // sets path for cta in SRP hero
    useEffect(() => {
        const getPersonalVehiclesPath = async () => {
            const path = await getPath(pageNames.SEARCH_RESULTS, { city, dma, zip }, { basePath: true });
            setPersonalVehiclesPath(path);
        };

        if (inSrpExperience) {
            getPersonalVehiclesPath();
        }
    }, [city, dma, zip, inSrpExperience]);

    const renderFilterChipsControls = (
        <div className="display-flex flex-nowrap">
            <SaveSearchContainer
                key="saveSearchContainer"
            />
            <InventoryClearFilters
                key="clearFilters"
                className="margin-right-3"
            />
        </div>
    );

    const handleKeywordExpand = () => {
        setKeywordExpand(!keywordExpand);
    };

    const renderKeywordBlock = (
        <>
            <KeywordSearchContainer className="col-xs-10" />
            <div className="col-xs-1 align-self-center">
                <Button
                    bsStyle="link"
                    onClick={() => handleKeywordExpand()}
                >
                    <Glyphicon
                        glyph="remove"
                        className="text-gray-darkest"
                    />
                </Button>
            </div>
        </>
    );

    const onlyPrivateSellerSelected = !Array.isArray(sellerType) && sellerType === 'p';

    const handlePrivateSellerExchangeLogoClick = (e) => {
        sendClick(psxPrivateSellerExchangeLogoClick, e);
    };

    return (
        <>
            {inSrpExperience && (
                <SrpHeroContainer
                    dataCmp={experienceName + '-srp-hero'}
                    heroAlt={heroAlt}
                    heroSrc={heroSrc}
                    isXs={isXs}
                    linkText={linkText}
                    locationHeading={locationHeading}
                    path={personalVehiclesPath}
                />
            )}
            <div className={clsx('padding-horizontal-2 padding-horizontal-sm-0', {
                container: inSrpExperience,
            })}
            >
                {isPersonalizationEnabled
                    ? (
                        <div className="text-gray-dark">
                            {savedSearchTitle}
                        </div>
                    ) : null}
                {enablePrivateSellerExchangeLogo && onlyPrivateSellerSelected && (
                    <Link
                        target="_blank"
                        href={privateSellerExchangeLogoHref}
                        onClick={handlePrivateSellerExchangeLogoClick}
                    >
                        <Image
                            src={privateSellerIcon}
                            alt="Private Seller Exchange"
                            height={27}
                            width={privateSellerIconWidth}
                            lazyload={false}
                            loading="eager"
                        />
                    </Link>
                )}
                {!inSrpExperience && renderBreadCrumbsAndHeading(locationHeading, false)}
                <div className={clsx({ 'row margin-top-3': enableKeyword })}>
                    {!isXs && (
                        <KeywordSearchContainer
                            className="col-sm-5 col-md-3 padding-vertical-1"
                            style={{ minWidth: '200px' }}
                        />
                    )}

                    {enableKeyword && isXs && !keywordExpand && <KeywordButton onClick={handleKeywordExpand} />}
                    {enableKeyword && isXs && keywordExpand && renderKeywordBlock}

                    <div
                        className={clsx({ 'col-xs-11 col-sm-7 col-md-9': enableKeyword })}
                        style={{ height: lessThanMd ? 43 : 47 }}
                    >
                        <div
                            className="display-flex flex-direction-row w-100"
                        >
                            {!lessThanMd && renderFilterChipsControls}
                            {!keywordExpand && (<div style={{ minWidth: '100px' }}><SuggestedFilterContainer /></div>)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchResultsTitleContainer;

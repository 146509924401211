import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@atc/react-device';

import {
    ItemCard,
    Panel,
} from 'reaxl';
import { InventoryListingImage } from 'reaxl-listing';

import InventoryDealerInfo from '@/components/InventoryDealerInfo/index.js';

// Change Contact Dealer
/**
 *  Dealer state Component for Alpha
 */
function OwnerAlphaListing({
    clickType,
    emailOwnerProps,
    listings,
    listingsCount,
    onInventoryListingClick = () => { },
    onGetDirectionsClick = () => { },
    onAlphaListingClick,
    onTileClick = () => { },
    onViewOwnerInventoryClick = () => { },
    orientation = 'landscape',
    owner = {},
    parentId,
    phoneClickHandler = () => { },
    uiContext = 'owner',
    ...rest
}) {

    const device = useDevice();
    const isLg = _get(device, 'is.lg', false);
    const rootClassName = clsx(rest.className, 'bg-info-100 inventory-listing');

    const dealerInfoProps = {
        clickType,
        listings,
        listingsCount,
        owner,
        uiContext,
        phoneClickHandler,
        emailOwnerProps,
        lazyLoad: false, // set lazyload to false since alpha is at the top of the page
        orientation,
        onInventoryListingClick,
        onGetDirectionsClick,
        onViewOwnerInventoryClick,
        onTileClick,
        parentId,
        key: 'alpha-listing-extra-dealer-info',
    };

    const renderBody = (
        <div className="padding-vertical-2 padding-horizontal-3"><InventoryDealerInfo {...dealerInfoProps} /></div>
    );

    return (
        <Panel
            data-cmp="ownerAlphaListing"
            className={rootClassName}
        >
            <ItemCard
                className="display-flex"
                uiContext="alpha"
                sideBar={isLg
                    && (
                        <InventoryListingImage
                            data={owner}
                            lazyLoad={false}
                            loading="eager"
                            orientation="landscape"
                            uiContext="alpha"
                            {...rest}
                        />
                    )}
                body={renderBody}
            />
        </Panel>
    );
}

OwnerAlphaListing.propTypes = {
    /**
     * The className to apply to the root node
     */
    className: PropTypes.string,
    /**
     * Alpha owner properties
     */
    owner: PropTypes.object,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * Handler for the Get Directions link
     */
    onGetDirectionsClick: PropTypes.func,
    /**
     * Handler for onInventoryListingClick
    */
    onInventoryListingClick: PropTypes.func,
    /**
     * Handler for onTileClick
     */
    onTileClick: PropTypes.func,
    /**
     * Handler for the View Dealer Inventory Link
     */
    onViewOwnerInventoryClick: PropTypes.func,
    /**
     * uiContext to control display of view dealer inventory
     */
    orientation: PropTypes.oneOf([
        'portrait',
        'landscape',
    ]),
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * The callback function fires when owner phone is called
     */
    phoneClickHandler: PropTypes.func,
    /**
     * uiContext to control display of inventory listing side bar
     */
    uiContext: PropTypes.oneOf(['owner', 'inventory']),
};

export default React.memo(OwnerAlphaListing, _isEqual);

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { connect, useSelector } from 'react-redux';

import qs from 'querystring';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import useUserData from '@kbbsdk/global-sdk/navigation/web/mykbb/useUserData';

import {
    IconButton,
} from 'reaxl';
import {
    sendClick,
} from 'reaxl-analytics';
import {
    emailSubscribeClick,
    emailSubscribeSignIn,
    facebookSubscribeSignIn,
    saveSearchClick,
    saveSearchSuccess,
    sendSubscribeAlertClick,
} from 'reaxl-analytics-handlers';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import LocalStorage from '@/utilities/LocalStorage';

import remoteActions from '@/actions/srp/remoteActions';

import useDataLayer from '@/analytics/useDataLayer';

import {
    authDuck,
    recentSearchDuck,
    userDuck,
} from '@/ducks';

import {
    srpFiltersDuck,
    srpResultsDuck,
    srpSortDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import { emailRegistrationServiceTransformer } from '@/transformers';

import EmailSubscribeAlertContainer from './EmailSubscribeAlertContainer';

const renderSaveSearchLink = ({ handleSaveSearch, className }) => (
    <IconButton
        className={clsx('text-nowrap text-bold', className)}
        onClick={handleSaveSearch}
        label="Save Search"
        glyph="heart-o"
        size="small"
    />
);

const SaveSearchContainerComponent = ({
    actions,
    className,
    isEmailCapture = false,
}) => {
    const dataLayer = useDataLayer();
    const navigateToSrp = useSrpNavigation();
    const { userInfo = {}, setUserInfo } = useUserData(); // used for KBB header dropdown only

    const { isBrand, brands } = useBrand();

    const search = useSelector(userDuck.selectors.getSavedSearch);
    const { saveSearchString } = search;
    const sort = useSelector(srpSortDuck.selectors.getDuckState, _isEqual);
    const requestValuesWithoutSort = useSelector(srpFiltersDuck.selectors.getRequestValues, _isEqual);
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const hasResults = useSelector(srpResultsDuck.selectors.hasActiveResults);
    const showEmailSubscribeInline = useSelector(userDuck.selectors.getShowEmailSubscribeInline);

    const requestValues = useMemo(() => ({
        ...requestValuesWithoutSort,
        sortBy: sort,
    }), [requestValuesWithoutSort, sort]);

    const {
        setEmailAlertsSearch,
        saveSearchItem,
        setEmailSubscribeInline,
    } = actions;

    const {
        global_personalization: [isGlobalPersonalizationEnabled],
        personalization: [isPersonalizationEnabled] = [],
        SAVE_SEARCH_OFF: [isSaveSearchOff],
        SUBSCRIBE_SEARCH_ALERTS: [isEmailAlertsSubscribeActive],
    } = useFeatures([
        'global_personalization',
        'personalization',
        'SAVE_SEARCH_OFF',
        'SUBSCRIBE_SEARCH_ALERTS',
    ]);

    // do not update selected filter values until the page is done loading
    const selectedFiltersValue = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);

    const hasSelectedFilterValues = !!selectedFiltersValue?.length;

    const [hasActiveSelectedFilterValues, setHasActiveSelectedFilterValues] = useState(hasSelectedFilterValues);

    useEffect(() => {
        if (hasResults) {
            setHasActiveSelectedFilterValues(hasSelectedFilterValues);
        }

    }, [hasResults, hasSelectedFilterValues]);

    const isSearchSavable = !isLoggedIn || !(search.title && search.title.length);
    const hasSearchParams = !!(requestValuesWithoutSort && Object.keys(requestValuesWithoutSort).length);
    const isSearchAlreadySubscribed = useSelector((state) => _get(recentSearchDuck.selectors.getRecentSearches(state), '[0].isSubscribed'));
    const isInlineSubscribeEnabled = !isLoggedIn && !isSearchAlreadySubscribed;
    const isInlineSubscribeCollapsed = isInlineSubscribeEnabled && !showEmailSubscribeInline;
    const showSaveSearchLink = (!isSaveSearchOff && hasSearchParams && isSearchSavable
        && (isPersonalizationEnabled || isGlobalPersonalizationEnabled) && hasActiveSelectedFilterValues);

    const setEmailAlerts = useCallback(() => {
        const parsedQuery = qs.parse(saveSearchString.slice(1));
        const transformedQuery = emailRegistrationServiceTransformer.transformSearch(parsedQuery);
        const searchData = isEmailCapture ? {
            ...transformedQuery,
            requestValues,
        } : transformedQuery;

        setEmailAlertsSearch({
            search: searchData,
            onLogInSuccess: (response) => {
                const {
                    provider,
                } = response;

                if (provider === 'facebook') {
                    sendClick(facebookSubscribeSignIn);
                } else {
                    sendClick(emailSubscribeSignIn);
                }
            },

            // onSaveSuccess:.....
            onSubmit: (event, data) => {
                sendClick(emailSubscribeClick, event, data);
            },
        });

        return searchData;
    }, [isEmailCapture, requestValues, saveSearchString, setEmailAlertsSearch]);

    const handleSaveSearch = useCallback(async (event) => {

        const data = {
            searchString: saveSearchString,
            requestValues: JSON.stringify(requestValues),
        };

        // The event must be 'persisted' to be referenced in asynchronous functions like to one below; otherwise its
        // properties may be overwritten by React before the asynchronous function has a chance to read them.
        // See https://reactjs.org/docs/events.html
        if (event && event.persist) {
            event.persist();
        }
        if (isEmailCapture) {
            sendClick(sendSubscribeAlertClick, event, {});
        } else {
            sendClick(saveSearchClick, event, {
                isLoggedIn,
                search: requestValues,
                dataLayer,
            });
        }

        if (isEmailAlertsSubscribeActive && !isLoggedIn) {
            setEmailAlerts();
        } else if (isInlineSubscribeCollapsed) {
            const Storage = LocalStorage.getInstance();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            Storage.useSession(true);
            Storage.clear('emailSubscribe');
            if (!isEmailCapture) {
                setEmailSubscribeInline(true);
            } else {
                return setEmailAlerts();
            }
        } else {
            saveSearchItem({
                data,
                onLogInSuccess: () => {
                    // fetcher call to get saved search data
                    // in case it's been already saved before

                    // push new instance of srp to history
                    navigateToSrp({ resetPagination: true });
                },
                onSaveSuccess: () => {
                    sendClick(saveSearchSuccess, event, { search: requestValues, dataLayer });
                    if (isBrand(brands.KBB_BRAND)) {
                        // increment count in header dropdown for KBB web component
                        setUserInfo({ ...userInfo, savedSearchesLength: (userInfo?.savedSearchesLength ? userInfo.savedSearchesLength : 0) + 1 });
                    }
                },
            });
        }
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isEmailAlertsSubscribeActive,
        isInlineSubscribeCollapsed,
        isLoggedIn,
        navigateToSrp,
        requestValues,
        saveSearchItem,
        saveSearchString,
        setEmailAlertsSearch,
        setEmailSubscribeInline,
    ]);

    const saveCta = isEmailCapture ? (!isLoggedIn && <EmailSubscribeAlertContainer handleSaveSearch={handleSaveSearch} />)
        : renderSaveSearchLink({ handleSaveSearch, className }) || null;

    return showSaveSearchLink && saveCta;
};

export const SaveSearchContainer = memo(SaveSearchContainerComponent, _isEqual);

const mapDispatchToProps = mapDispatchToActionProp({
    setEmailAlertsSearch: remoteActions.setEmailAlertsSearch,
    saveSearchItem: userDuck.creators.saveSearchItem,
    setEmailSubscribeInline: userDuck.creators.showEmailSubscribeInline,
});

export default connect(null, mapDispatchToProps)(SaveSearchContainer);

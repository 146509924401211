import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _isEqual from 'lodash/isEqual';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { sendImpressions } from 'reaxl-analytics';
import { totalNumberListingsImpressions } from 'reaxl-analytics-handlers';

import {
    srpNewCarBoostDuck,
    srpResultsDuck,
    srpSpotlightDuck,
} from '@/ducks/srp';

import { inventoryImpressions, newCarBoostImpressions, spotlightImpressions, vehicleList } from './srpAnalyticsHandlers';

export default function useSrpSearchResultsAnalytics({
    listingCount = 0,
    currentPage,
    totalNumberListingsImpressionsCallback = () => { },
    regularInventoryImpressionsCallback = () => { },
}) {
    const { onPageEnter: onPageEnterResults } = usePageEvent('results');
    const { onPageEnter: onPageEnterSpotlights } = usePageEvent('spotlights');
    const { onPageEnter: onPageEnterUpsells } = usePageEvent('upsells');

    const hasResults = useSelector(srpResultsDuck.selectors.hasActiveResults);
    const { count: resultCount } = useSelector(srpResultsDuck.selectors.getDuckState);
    const spotlightIds = useSelector((state) => {
        const spotlights = srpSpotlightDuck.selectors.getActiveInventory(state) || [];
        return spotlights.map((spotlight) => spotlight?.id);
    }, _isEqual);

    const boostListings = useSelector(srpNewCarBoostDuck.selectors.getAvailableInventory);
    const imeListings = useSelector(srpResultsDuck.selectors.getActiveInventory).filter((listing) => listing.marketExtension?.imeDmaMatch);

    /*
     * This useEffect hook will fire impressions for regular listings
     * when two criteria have been met which indicate there are new listings on
     * the page ready to be fired
     */
    onPageEnterResults(() => {
        if (hasResults) {
            sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'lstg',
                },
            });
        }
        sendImpressions({
            name: vehicleList,
        });
        if (!listingCount && currentPage === 1) {
            sendImpressions({
                data: {
                    resultCount,
                },
                name: totalNumberListingsImpressions,
            });
            totalNumberListingsImpressionsCallback();
        }
        if (imeListings.length) {
            sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'ime',
                    selectedItemIds: imeListings.map((listing) => listing.id),
                },
            });
        }
        regularInventoryImpressionsCallback();
    });

    onPageEnterSpotlights(() => {
        if (spotlightIds && spotlightIds.length > 0) {
            sendImpressions({
                name: spotlightImpressions,
                data: {
                    activeInventory: spotlightIds,
                },
            });
        }
    });

    /*
     * This useEffect hook will fire impressions for new car boost listings
     */
    const boostListingsIds = boostListings?.map((boostListing) => boostListing?.id);
    useEffect(() => {
        onPageEnterUpsells(() => {
            if (boostListings && boostListings.length > 0) {
                sendImpressions({
                    name: newCarBoostImpressions,
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...boostListingsIds]);

    return {
        getInventoryAnalyticsProps: ({
            listingCategory,
            index,
        }) => ({
            clickType: getClickType(listingCategory),
            parentId: getParentId(listingCategory, index),
        }),
    };
}

import React from 'react';

import { connect } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { useFeatures } from 'reaxl-features';

import adUnits from '@/config/adUnits';

import srpAdsDuck from '@/ducks/srp/srpAdsDuck';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

const backFillSize = [320, 50];

export function FooterLeaderAdSlotContainer({
    adsLazyLoadingOffset,
}) {
    const device = useDevice();

    const {
        SRP_ADS_PLACEHOLDER_OFF: [srpAdsPlaceholderDisabled] = false,
        srp_ads_single_size: [isSingleSizeOnly],
        preserve_ad_space: [preserveAdSpace, {
            footer_ad_height_desktop: maxHeightDesktop,
            footer_ad_height_mobile: maxHeightMobile,
        }],
    } = useFeatures(['SRP_ADS_PLACEHOLDER_OFF', 'srp_ads_single_size', 'preserve_ad_space']);

    // ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
    let extraSmallSize = isSingleSizeOnly ? [backFillSize] : [backFillSize, [320, 250]];
    let normalSize = [[728, 90]];

    if (preserveAdSpace) {
        extraSmallSize = [
            ...extraSmallSize,
            ...[[1, maxHeightMobile]],
        ];
        normalSize = [
            ...normalSize,
            ...[[1, maxHeightDesktop]],
        ];
    }

    const isXs = _get(device, 'is.xs', false);
    const size = isXs ? extraSmallSize : normalSize;

    const targeting = {
        anml: 'N',
        pos: 'e',
        prd: isXs ? null : 'dlr',
    };

    const path = `${adUnits?.atcFindcar}/srp`;

    return (
        <DisableAdsFeatureFlag>
            <AtcAdSlot
                className="display-flex flex-column justify-content-center"
                lazyLoading
                lazyLoadingBottomOffset={adsLazyLoadingOffset}
                lazyLoadingTopOffset={adsLazyLoadingOffset}
                path={path}
                preserveSpace={isSingleSizeOnly || preserveAdSpace}
                showPlaceholder={!srpAdsPlaceholderDisabled}
                size={size}
                slotId="footerLeader"
                targeting={targeting}
            />
        </DisableAdsFeatureFlag>
    );
}

const mapStateToProps = (state) => ({
    adsLazyLoadingOffset: srpAdsDuck.selectors.getAdsLazyLoadingOffset(state),
});

export default connect(mapStateToProps)(FooterLeaderAdSlotContainer);

import url from 'url';

import _cloneDeep from 'lodash/cloneDeep';
import _isArray from 'lodash/isArray';

// Function to sort query parameters and their values
export const sortQueryParam = (originalUrl) => {
    const parsedUrl = url.parse(originalUrl, true);
    const queryParams = parsedUrl.query;

    const sortedParams = Object.keys(queryParams)
        .sort() // Sort Params By Name
        .reduce((acc, key) => {
            const values = _cloneDeep(_isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]]);
            acc[key] = values.sort(); //  Sort Multi-Value Params
            return acc;
        }, {});

    return sortedParams;
};

// Function to create a sorted query string
export const createSortedQueryString = (sortedParams) => Object.keys(sortedParams)
    .map((key) => sortedParams[key].map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&'))
    .join('&');

import React from 'react';

import { useDispatch } from 'react-redux';

import { MyWalletInputCard } from 'reaxl-wallet';

import { paymentsDuck } from '@/ducks';

import { srpReuseActiveStateDuck } from '@/ducks/srp';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function WalletInputCardContainer() {

    const dispatch = useDispatch();
    const navigateToSrp = useSrpNavigation();

    const handleSearchAvailVehiclesClick = async (maxPrice) => {
        const newFiltersValues = {
            maxPrice,
        };

        await dispatch(srpReuseActiveStateDuck.creators.setFalse());
        navigateToSrp({
            filtersValues: newFiltersValues,
            isNewSearch: true,
            resetPagination: true,
        });
    };

    return (
        <div className="col-xs-12 col-sm-4">
            <MyWalletInputCard
                paymentsDuck={paymentsDuck}
                onSearchAvailVehiclesClick={handleSearchAvailVehiclesClick}
                paymentCalculationFetcher={PaymentCalculationFetcher}
            />
        </div>
    );
}

export default WalletInputCardContainer;

import React, { memo } from 'react';

import { connect } from 'react-redux';

import Link from 'next/link';

import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';

import { removeBasePath } from '@bonnet/next/base-path';

import { Breadcrumbs } from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { Breadcrumb as BreadcrumbSchema } from 'reaxl-schema';

import srpBreadcrumbsDuck from '@/ducks/srp/srpBreadcrumbsDuck';

function SRPBreadcrumbsContainer({
    breadcrumbs,
    schemaItems,
}) {
    const {
        breadcrumbs: [isBreadcrumbsEnabled] = [],
    } = useFeatures(['breadcrumbs']);
    // Reduce the breadcrumb array to only those that have filter values selected
    // NOTE: label will return undefined if no filter is selected
    const getActiveBreadcrumbs = () => breadcrumbs.reduce((acc, crumb) => {
        if (crumb.label) {
            acc.push(crumb);
        }
        return acc;
    }, []);

    const getActiveSchemaItems = () => schemaItems.reduce((acc, item) => {
        const breadcrumb = breadcrumbs.filter((crumb) => crumb.key === item.key)[0];
        if (breadcrumb.label) {
            acc.push(item);
        }
        return acc;
    }, []);

    // TODO: BONNET NEXT - use of removeBasePath is because we re-enabled all
    // internal URLS to still be created with basePath but next/link will re-append
    const renderBreadcrumb = (breadcrumb, i, arr) => (
        <Link
            {...breadcrumb}
            href={removeBasePath(breadcrumb.href)}
            className={
                clsx(
                    {
                        'text-subdued-lightest': i < arr.length - 1 || i === 0,
                        'text-gray-base': i === arr.length - 1,
                    },
                    'text-size-200 padding-right-2 display-inline-block link-undecorated'
                )
            }
        >
            {breadcrumb.label}
        </Link>
    );

    return !!isBreadcrumbsEnabled
        && (
            <Breadcrumbs
                data-cmp="breadcrumbs"
                id="breadcrumbs-container"
                items={getActiveBreadcrumbs(breadcrumbs)}
                render={renderBreadcrumb}
            >
                <BreadcrumbSchema config={{
                    crumbs: getActiveSchemaItems(schemaItems),
                }}
                />
            </Breadcrumbs>
        );
}

const mapStateToProps = (state) => {
    const breadcrumbs = srpBreadcrumbsDuck.selectors.getBreadcrumbs(state);
    const schemaItems = srpBreadcrumbsDuck.selectors.getSchemaItems(state);

    return {
        breadcrumbs,
        schemaItems,
    };
};

export default connect(mapStateToProps)(memo(SRPBreadcrumbsContainer, (prevProps, nextProps) => _isEqual(prevProps.breadcrumbs, nextProps.breadcrumbs)));

import React from 'react';

import { useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import { LoadingSpinner } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { srpResultsDuck, srpSimilarListingsDuck, srpSupplementalDuck } from '@/ducks/srp';

import LazyComponent from '@/components/LazyComponent';

const AlternativeSearchAction = dynamic(() => import(
    /* webpackChunkName: "AlternativeSearchAction" */
    '@/components/AlternativeSearchAction'
), {
    ssr: false,
    loading: () => (
        <LoadingSpinner
            className="margin-auto"
            height={182}
        />
    ),
});
function SRPAlternativeSearchActionContainer() {
    const {
        srp_bottom_lazy_threshold: [, { defaultThreshold }],
    } = useFeatures(['srp_bottom_lazy_threshold']);

    const isSimilarLoading = useSelector(srpSimilarListingsDuck.selectors.getResultLoading);
    const listingResultCount = useSelector(srpResultsDuck.selectors.getResultsCount);
    const supplementalResultCount = useSelector(srpSupplementalDuck.selectors.getResultCount);

    const enableAlternativeSearchAction = (supplementalResultCount + listingResultCount <= 25) || listingResultCount === 0;

    return enableAlternativeSearchAction && !isSimilarLoading && (
        <LazyComponent
            height={{ xs: 400, sm: 308, md: 251, lg: 182 }}
            threshold={defaultThreshold}
        >
            <AlternativeSearchAction />
        </LazyComponent>
    );
}

export default React.memo(SRPAlternativeSearchActionContainer);

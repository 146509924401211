import _get from 'lodash/get';

import { formatCurrency, formattedNumber } from 'atc-js';

import { brands } from 'reaxl-brand';

import customAddOnContent from '@/config/customAddOnContent';

const SUMMARY_CONTENT = 'Autotrader has {0}{1}{2}{3}{4}{5}for sale{6}{7}{8}{9}{10}.';
const FORD_SUMMARY_CONTENT = 'The Ford Blue Advantage program has {0} certified used {4}{2}{3}{11}available{7}{8}.';

const META_DESCRIPTION_ADD_ON = 'Search from {0}{1}{2}{3}{4}{5}for sale{6}{7}.';

const FUEL_TYPE_SUMMARY_CONTENTS = [{
    code: 'ELE',
    value: 'electric',
},
{
    code: 'GSL',
    value: 'gasoline',
},
{
    code: 'DSL',
    value: 'diesel',
},
{
    code: 'HYB',
    value: 'hybrid',
},
{
    code: 'ALT',
    value: 'alternative',
},
{
    code: 'HYD',
    value: 'hydrogen',
}];

const NO_S_STYLE_CODES = ['LUXURY', 'HYBEL', 'COMMERCIAL', 'AWD4WD'];

export const replaceParams = (str, replacements) => str.replace(/\{(\d+)\}/g, function replaceParam() {
    // eslint-disable-next-line prefer-rest-params
    return replacements[arguments[1]] || '';
});
/**
 * Parses "list" String values.
 */
export const parseValue = (value) => (value && !value.includes(',') ? value : '');

/**
 * Get summary content of fuel type
 */
export const getFuelTypeSummaryContent = (fuelTypeValue) => {
    if (fuelTypeValue) {
        const fuelTypeSummaryContent = FUEL_TYPE_SUMMARY_CONTENTS.find((item) => item.code === fuelTypeValue)?.value;
        return fuelTypeSummaryContent ? `${fuelTypeSummaryContent} ` : '';
    }
    return '';
};

export const getStyleModelSummaryContent = (vehicleStyleSummaryContent, modelDescription, totalListing, vehicleText) => {
    let returnValue = vehicleStyleSummaryContent;
    if (modelDescription && !returnValue) {
        returnValue = modelDescription.trim() + ' ';
    }
    return returnValue || '';
};

export const priceFormat = (startingPrice) => {
    const priceValue = startingPrice && formattedNumber({
        value: startingPrice,
        style: 'currency',
        currency: 'USD',
        formatPretty: true,
    });
    return priceValue;
};

export const getVehicleStyleDescriptionPluralById = (vehicleStyleCodes, vehicleStyleCode) => {
    const ret = vehicleStyleCodes?.find((item) => item.value === vehicleStyleCode);

    let vehicleStyle = '';

    if (ret) {
        const noSCode = NO_S_STYLE_CODES.find((item) => item === vehicleStyleCode);
        if (!noSCode) {
            vehicleStyle = ret?.label?.split(' / ').map((style) => style + 's').join(' / ');
        }
    }

    return vehicleStyle ? vehicleStyle + ' ' : '';
};

/**
 * Builds up the list of titles so we don't duplicate one in the samples.
 */
export const buildTitleList = (inventory, condition) => {
    const titleSet = new Set();

    Object.values(inventory).forEach((listing) => {
        const title = listing?.title;

        if (condition) {
            // can't use regex literals with variables

            titleSet.add(title.replace(new RegExp(condition, 'i'), '').trim());
        } else {
            titleSet.add(title.trim());
        }
    });

    return titleSet;
};

export const articlePicker = (str) => {
    if (str) {
        if ('AEIOUaeiou'.indexOf(str.charAt(0)) < 0) {
            str = 'a ' + str;
        } else {
            str = 'an ' + str;
        }
    }
    return str;
};

export const getCarText = (vehicleStyleSummaryContent, totalListingCount) => {
    const carText = totalListingCount > 1 ? 'cars ' : 'car ';
    return vehicleStyleSummaryContent ? carText : '';
};

export const getListingType = (listingType) => {
    switch (listingType) {
        case 'USED':
            return 'Used';
        case 'CERTIFIED':
            return 'Certified';
        case '3P_CERT':
            return 'Third-Party Certified';
        case 'NEW':
            return 'New';
        default:
            return '';
    }
};

export const buildCustomAddOnContent = (customContent, query = {}) => {
    if (customContent) {
        const { content = {} } = customContent.find(({ filterOptions = {} }) => Object.entries(filterOptions).every(([key, value]) => query[key] === value)) || {};
        return content;
    }
    return {};
};

export const buildSummaryContent = ({
    brand,
    condition,
    inventory,
    makeDescription,
    modelDescription,
    minPrice,
    maxPrice,
    query,
    summaryContentOutput,
    totalListing,
    vehicleStyleCodes,
    vehicleText,
}) => {

    const totalResultCount = totalListing ? formattedNumber({ value: totalListing }) + ' ' : '';

    const privateSeller = parseValue(query?.sellerTypes) === 'p' ? ' by owner' : '';

    const fuelTypeSummaryContent = getFuelTypeSummaryContent(query?.fuelTypeGroup);

    const vehicleStyleSummaryContent = parseValue(getVehicleStyleDescriptionPluralById(vehicleStyleCodes, query?.vehicleStyleCode));

    const styleModel = getStyleModelSummaryContent(vehicleStyleSummaryContent, modelDescription);

    vehicleText = styleModel ? '' : vehicleText;

    let priceRange = '';
    const minPriceQuery = query?.minPrice;
    const maxPricePrice = query?.maxPrice;

    if (minPriceQuery && !maxPricePrice) {
        priceRange = ' above ' + priceFormat(minPriceQuery);
    } else if (!minPriceQuery && maxPricePrice) {
        priceRange = ' under ' + priceFormat(maxPricePrice);
    } else if (minPriceQuery && maxPricePrice) {
        priceRange = ' between ' + priceFormat(minPriceQuery) + ' and ' + priceFormat(maxPricePrice);
    }

    let location = '';
    if (query?.city && query?.state) {
        location = brand === brands.FORD_BRAND
            ? ' in ' + query.city + ', ' + query.state
            : ' near ' + query.city + ', ' + query.state;
    }

    let sampleListings = '';
    const titles = buildTitleList(inventory, condition);
    if (titles.size > 1) {
        const listings = [...titles].sort();
        if (listings.length === 2) {
            sampleListings = ', including a ' + listings[0] + ' and a ' + listings[1];
        } else {
            sampleListings = ', including a ' + listings[0] + ', a ' + listings[1] + ', and a ' + listings[2];
        }
    }

    let priceStats = '';
    if (minPrice && maxPrice && minPrice !== maxPrice) {
        priceStats = ' ranging in price from ' + minPrice + ' to ' + maxPrice;
    }

    const carText = styleModel ? '' : getCarText(vehicleStyleSummaryContent, totalListing);

    const summaryContent = brand === brands.FORD_BRAND ? FORD_SUMMARY_CONTENT : SUMMARY_CONTENT;

    const summaryContentArgs = [totalResultCount, condition, makeDescription, styleModel, fuelTypeSummaryContent,
        carText, privateSeller, priceRange, location, sampleListings, priceStats, vehicleText];
    summaryContentOutput.summaryContent = replaceParams(summaryContent, summaryContentArgs);
    summaryContentOutput.customAddOnContent = buildCustomAddOnContent(customAddOnContent[brand], query);

    const metaDescriptionAddOnArgs = [totalResultCount, condition, makeDescription, styleModel,
        fuelTypeSummaryContent, carText, sampleListings, priceStats];
    summaryContentOutput.metaDescriptionAddOn = replaceParams(META_DESCRIPTION_ADD_ON, metaDescriptionAddOnArgs);

    return summaryContentOutput;
};

export const createSummaryContent = (data, query) => {
    const { listingTypes } = query;
    const makeCodeList = query.makeCode;
    const modelCodeList = query.modelCode;

    const makeCode = parseValue(makeCodeList);
    let makeDescription = '';
    if (makeCode) {
        const makes = data.filters?.makeCode?.options;
        const make = makes?.find((item) => item.value === makeCode);

        if (make) {
            makeDescription = make.label + ' ';
        }
    }

    const modelCode = parseValue(modelCodeList);
    let modelDescription = '';
    if (modelCode) {
        const models = data.filterGroups?.modelCode?.[makeCode]?.options;
        const model = models?.find((item) => item.value === modelCode);

        if (model) {
            modelDescription = model.label + ' ';
        }
    }

    let condition = parseValue(listingTypes);
    if (condition) {
        const listingType = getListingType(condition);
        if (listingType !== '') {
            condition = listingType + ' ';
        }
    }

    const vehicleStyleCodes = data.filters?.vehicleStyleCode?.options;

    const brand = data?.brand;

    let summaryContentOutput = {
        metaDescriptionAddOn: '',
        selectedMake: '',
        summaryContent: '',
        customAddOnContent: {},
    };

    summaryContentOutput.selectedMake = makeDescription.trim();

    const totalListing = _get(data, 'totalResultCount', 0);

    const inventory = _get(data, 'listings', []);

    const minPrice = formatCurrency(data.stats?.derivedprice?.min);
    const maxPrice = formatCurrency(data.stats?.derivedprice?.max);

    let vehicleText = 'vehicles ';
    if (totalListing === 1) {
        vehicleText = 'vehicle ';
    }
    const selectedStyleCodes = vehicleStyleCodes?.map((style) => style.selected || false);
    if (selectedStyleCodes?.some(Boolean)) {
        vehicleText = '';
    }

    summaryContentOutput = buildSummaryContent({
        brand,
        condition,
        inventory,
        makeDescription,
        modelDescription,
        minPrice,
        maxPrice,
        query,
        summaryContentOutput,
        totalListing,
        vehicleStyleCodes,
        vehicleText,
    });

    return summaryContentOutput;
};

import genericPostJSON from '@/utilities/genericPostJSON';

export default ({
    consumerId = '',
    email = '',
    brand = '',
    vins = [],
}) => {
    const endpoint = '/cars-for-sale/still-interested/alert';

    const requestBody = {
        brand,
        vins,
    };

    if (consumerId) {
        requestBody.consumer_id = consumerId;
    }

    if (email) {
        requestBody.email = email;
    }

    if (navigator && navigator.sendBeacon) {
        // Need to convert to a blob to beacon out with content-type header
        const requestBlob = new Blob([JSON.stringify(requestBody)], { type: 'application/json' });
        navigator.sendBeacon(endpoint, requestBlob);
    } else {
        genericPostJSON(endpoint, JSON.stringify(requestBody));
    }
};

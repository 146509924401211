import { createSortedQueryString, sortQueryParam } from '@/utilities/sortQueryParam';

export default function withSortQuery() {

    return async (ctx, next) => {
        const { asPath } = ctx;
        const [originalPath, params] = asPath.split('?');
        if (params) { // Ignore URLs without params
            const sortedParams = sortQueryParam(asPath);
            const srpPath = `${originalPath}?${createSortedQueryString(sortedParams)}`;
            if (ctx.res && asPath !== srpPath) {
                const statusCode = 301;
                ctx.res.redirect(statusCode, process.env.BASE_PATH + srpPath);
                ctx.res.end();
                ctx.statusCode = statusCode;
                next('server redirect');
            }
        }
    };

}

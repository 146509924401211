import React, { memo } from 'react';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { Button } from 'reaxl';
import { PromoCard } from 'reaxl-listing';

function CommercialVehicleCard() {

    const device = useDevice();

    const isGreaterThanXs = _get(device, 'greaterThan.xs', false);

    const renderSearchCTA = (
        <div className="padding-horizontal-1">
            We&apos;ve rounded up a few choices that could be a perfect match.
            <Button
                bsStyle="secondary"
                bsSize="large"
                className={clsx('col-xs-12 padding-horizontal-3 margin-vertical-5', {
                    'text-size-300': isGreaterThanXs, 'text-size-400': !isGreaterThanXs,
                })}
                href="/cars-for-sale/commercial"
            >
                Search Commercial Vehicles
            </Button>
        </div>
    );

    return (
        <PromoCard
            data-cmp="commercialVehicleCard"
            bodyClassName="col-xs-12 padding-top-sm-6 padding-top-3 padding-horizontal-4 fade-in"
            className="col-xs-12 col-sm-4 display-flex"
            heading="Considering Commercial?"
            imgHeight="99px"
            imgWidth="220px"
            imgSrc="/content/static/img/commerical/commercial_truck_trans_sized.png"
            subText={renderSearchCTA}
        />
    );
}
export default memo(CommercialVehicleCard);

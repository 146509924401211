import React from 'react';

function FilterAccordionsPlaceholder() {
    const filterBaseChildren = [
        'filterBase_0',
        'filterBase_1',
        'filterBase_2',
        'filterBase_3',
    ];

    const filterBase = (children) => (
        <div className="container-fluid padding-4 skeleton__panel">
            <div className="row skeleton__loading margin-bottom-4">
                <div className="col-xs-4">
                    <div className="skeleton__headline skeleton__placeholder" />
                </div>
                <div className="col-xs-2 col-xs-offset-6">
                    <div className="skeleton__text skeleton__placeholder" />
                </div>
            </div>
            {children}
        </div>
    );
    return (
        <div
            data-cmp="FiltersContainerPlaceholder"
            style={{ height: 2296 }}
        >
            {/* location */}
            {filterBase((
                <>
                    <div className="row skeleton__loading">
                        <div className="col-xs-5">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>

                        <div className="col-xs-5 col-xs-offset-2">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>
                    </div>

                    <div className="row skeleton__loading margin-bottom-5">
                        <div className="col-xs-5">
                            <div className="skeleton__spacer skeleton__placeholder" />
                        </div>

                        <div className="col-xs-5 col-xs-offset-2">
                            <div className="skeleton__spacer skeleton__placeholder" />
                        </div>
                    </div>

                    <div className="row skeleton__loading margin-top-4">
                        <div className="col-xs-1 col-xs-offset-2">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>

                        <div className="col-xs-8">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>
                    </div>
                </>
            ))}

            {/* year */}
            {filterBase((
                <>
                    <div className="row skeleton__loading">
                        <div className="col-xs-5">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>

                        <div className="col-xs-5 col-xs-offset-2">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>
                    </div>

                    <div className="row skeleton__loading">
                        <div className="col-xs-5">
                            <div className="skeleton__spacer skeleton__placeholder" />
                        </div>

                        <div className="col-xs-5 col-xs-offset-2">
                            <div className="skeleton__spacer skeleton__placeholder" />
                        </div>
                    </div>
                </>
            ))}

            {/* mmt */}
            {filterBase((
                <>
                    <div className="row skeleton__loading">
                        <div className="col-xs-5">
                            <div className="skeleton__text skeleton__placeholder" />
                        </div>
                    </div>

                    <div className="row skeleton__loading">
                        <div className="col-xs-11">
                            <div className="skeleton__spacer skeleton__placeholder" />
                        </div>
                    </div>
                </>
            ))}

            {/* condition */}
            {filterBase(filterBaseChildren.map((value) => (
                <div
                    className="row skeleton__loading margin-top-4"
                    key={value}
                >
                    <div className="col-xs-1">
                        <div className="skeleton__text skeleton__placeholder" />
                    </div>

                    <div className="col-xs-11">
                        <div className="skeleton__text skeleton__placeholder" />
                    </div>
                </div>
            )))}

            {/* drivetype && top of payments*/}
            {filterBase()}
            {filterBase()}
        </div>
    );
}

export default FilterAccordionsPlaceholder;

import React from 'react';

import { Glyphicon, Text } from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { PromoCard } from 'reaxl-listing';

function SellYourCarPlacementContainer() {
    const { brand } = useBrand();

    const offsiteLink = brand === 'atc' ? 'https://www.autotrader.com/sell-my-car/' : 'https://www.kbb.com/sell-your-car/';

    const heading = brand === 'atc' ? 'Sell Your Car on Autotrader' : ' List Your Car on Kelley Blue Book®';

    const handleCtaClick = () => {
        window.open(offsiteLink, 'noopener');
    };

    return (
        <PromoCard
            buttonText={(
                <>
                    List My Car
                    <Glyphicon
                        glyph="offsite"
                        className="margin-left-2"
                    />
                </>
            )}
            buttonStyle={{ width: '90%' }}
            className="col-xs-12 col-sm-4 display-flex"
            data-cmp="sycPlacement"
            handleButtonClick={handleCtaClick}
            heading={heading}
            imgHeight={62}
            imgWidth={62}
            imgSrc="/content/static/img/icon/myWallet/grid/affordable_new_cars.svg"
            subText={<Text componentClass="div">On average, sellers receive 28% more for their vehicle by listing on Private Seller Exchange compared to trade-in.</Text>}
        />
    );
}

export default SellYourCarPlacementContainer;

import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import interactionResponse from 'await-interaction-response';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _set from 'lodash/set';

import { useDevice } from '@bonnet/next/device';

import { sendClick } from 'reaxl-analytics';
import { myWalletMobileToolbarClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { FilteredResultsControls } from 'reaxl-filters';

import { srpSortDropDownClick } from '@/analytics/srpAnalyticsHandlers';

import inventorySortOptions from '@/config/sort';

import { paymentsDuck } from '@/ducks';

import {
    srpDisableScrollDuck,
    srpFiltersDuck,
    srpSortDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function FilterMobileControlsContainer({
    renderCompareToggle,
    isStickyHeader,
}) {
    const dispatch = useDispatch();
    const navigateToSrp = useSrpNavigation();
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const lessThanMd = _get(device, 'lessThan.md', false);

    const {
        compare: [showCompare],
        my_wallet: [enableMyWallet],
        remove_payments_toggle: [removePaymentsToggle, {
            wallet_text: optimizelyWalletText,
        }],
    } = useFeatures([
        'compare',
        'my_wallet',
        'remove_payments_popover',
        'remove_payments_toggle',
    ]);

    const currentSort = useSelector(srpSortDuck.selectors.getDuckState, _isEqual);

    // do not update selected filter values until the page is done loading
    const filters = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);
    const filterCount = filters.length;

    const sortData = { ...inventorySortOptions, value: currentSort };

    const handleSortChange = useCallback(async (event) => {
        const sortType = _get(event, 'target.value', null) || event;
        await interactionResponse();

        _set(event, 'target.value', sortType);

        // allow the page to scroll to top on page change
        dispatch(srpDisableScrollDuck.creators.setFalse());
        // set sort based on target value
        dispatch(srpSortDuck.creators.setSort(sortType));

        sendClick(srpSortDropDownClick, event, {
            sortType,
        });
        navigateToSrp({ resetPagination: true, isNewSearch: false });
    }, [dispatch, navigateToSrp]);

    const handleWalletClick = async (event) => {
        await interactionResponse();
        dispatch(paymentsDuck.creators.updateMyWallet({ displayModal: true }));
        sendClick(myWalletMobileToolbarClick, event);
    };

    const handleFilterClick = async () => {
        await interactionResponse();
        dispatch(srpFiltersDuck.creators.showFilters());
    };

    return (
        <FilteredResultsControls
            sortData={sortData}
            onFilterClick={handleFilterClick}
            onSortByChange={handleSortChange}
            onWalletClick={handleWalletClick}
            renderCompareToggle={renderCompareToggle}
            filterCount={filterCount}
            showCompare={showCompare}
            showWallet={(enableMyWallet || isXs)}
            showFilter={(showCompare && isXs) || lessThanMd}
            paymentsDuck={paymentsDuck}
            optimizelyWalletText={removePaymentsToggle ? optimizelyWalletText : undefined}
            isStickyHeader={isStickyHeader}
        />
    );
}

export default memo(FilterMobileControlsContainer);

import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import { RecommendedText } from 'reaxl';
import {
    InventoryListingV2,
} from 'reaxl-listing';
import { Listing as ListingSchema } from 'reaxl-schema';

import configureListingTiles from '@/utilities/configureListingTiles';
import useInventoryListingProps from '@/utilities/useInventoryListingProps';

import {
    srpNewCarBoostDuck,
} from '@/ducks/srp';

export function InventoryBoostContainer({
    baseUrl,
    schemaTemplate,
    schemaType,
    userLocation,
    currentPage,
    listingIndex,
    brand,
    certifiedSponsor,
    isLSCEnabled,
    isOverrideCarfaxTileEnabled,
    partner,
    removeCarfaxTile,
}) {

    const listingProps = {
        listingIndex,
        brand,
        certifiedSponsor,
        isLSCEnabled,
        isOverrideCarfaxTileEnabled,
        partner,
        removeCarfaxTile,
    };

    // Getting the specific boost base on index and configure it
    const boostListing = useSelector((state) => srpNewCarBoostDuck.selectors.getIndividualListing(state, listingIndex));
    const boostInventoryListing = !!boostListing && configureListingTiles({
        ...listingProps,
        tier: 'boost',
        items: [boostListing],
    });
    const currentListing = boostInventoryListing[0];

    const resultsCount = useSelector(srpNewCarBoostDuck.selectors.getActiveResultsCount);
    const paginatedListingIndex = (listingIndex && (listingIndex + 1) + ((currentPage - 1) * resultsCount)) || undefined;

    const getListingProps = useInventoryListingProps();

    return currentListing ? (
        <div
            className="col-xs-12 col-sm-4 display-flex"
            key={`new-car-boost-container-${listingIndex}`}
            id={`new-car-boost-container-${listingIndex}`}
        >
            <Fragment key={currentListing.id}>
                <div
                    key={`recommendedText-${currentListing.id}`}
                    className="display-block me text-right"
                    style={{ position: 'absolute', top: -20 }}
                >
                    <RecommendedText
                        width="72"
                        height="10"
                    />
                </div>
                {/* check if schema enabled*/}
                <ListingSchema
                    inventory={currentListing}
                    baseUrl={baseUrl}
                    schemaTemplate={schemaTemplate}
                    schemaType={schemaType}
                    searchLocation={userLocation}
                />
                <InventoryListingV2
                    data-cmp="inventoryBoostListing"
                    {...getListingProps('boost', currentListing, listingIndex, paginatedListingIndex)}
                    className="cursor-pointer display-flex flex-column"
                    panelHeaderText="Consider New at Comparable Prices"
                />
            </Fragment>
        </div>
    ) : null;
}

export default InventoryBoostContainer;
